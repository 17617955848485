<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>family-grandfather</title>
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="15" cy="3.75" r="3" />
    <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" x1="15" y1="8.25" x2="15" y2="14.25" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M17.25,23.25l.75-7.5h1.5v-3a4.5,4.5,0,0,0-9,0v3H12l.75,7.5Z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M4.5,15v-.75A1.5,1.5,0,0,1,6,12.75H6a1.5,1.5,0,0,1,1.5,1.5v9"
    />
  </svg>
</template>
